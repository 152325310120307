export interface IAnimalsState {
    animals: any[],
    ettCode: string;
    blnNumber: string;
    isLoading: boolean;
    hasError: boolean;
    errorMessage: string;
    multipleAnimals: {
        count: number,
        total: number,
        isLoading: boolean,
        hasError: boolean;
        errorMessage: string;
        success: boolean,
    }
}

export const initialAnimalsState: IAnimalsState = {
    animals: null,
    ettCode: null,
    blnNumber: null,
    isLoading: false,
    hasError: false,
    errorMessage: null,
    multipleAnimals: {
        count: null,
        total: null,
        isLoading: false,
        hasError: false,
        errorMessage: null,
        success: false,
    }
};
