import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IAnimalsState } from '@app/store/animals/animals.state';

export const animalsState = createFeatureSelector<IAnimalsState>('animals');

export const getAnimalsState = createSelector(
    animalsState,
    (state: IAnimalsState) => state
);

export const getAnimals = createSelector(
    animalsState,
    (state: IAnimalsState) => state.animals
);

export const getDownloadedAnimals = createSelector(
    animalsState,
    (state: IAnimalsState) => state.multipleAnimals
);


