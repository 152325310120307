import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map, switchMap, flatMap } from 'rxjs/operators';
import { AuthService } from '@app/services/auth/auth.service';
import { TestsApiService } from '@app/store/services/tests-api.service';
import { TestsService } from '@app/services/tests/tests.service';
import { AnimalsService } from '@app/services/animals/animals.service';
import { ToastService } from '@app/services/toast.service';
import { animalsActions } from '@app/store/animals/animals.actions';

@Injectable()
export class AnimalsEffects {

    getAnimals$ = createEffect(
        () => this.actions.pipe(
            ofType(animalsActions.getAnimals),
            switchMap((action: any) => {
                return from(this.testsApiService.getAnimals(action.blnNumber, action.ettCode)).pipe(
                    map((animals: any) => {
                        if (animals) {
                            this.animalService.setAnimalsList(animals, action.blnNumber, action.ettCode);
                            this.animalService.setAnimalsDate(action.blnNumber, action.ettCode);
                        }
                        return animalsActions.setAnimals({ animals });
                    }),
                    catchError((err) => {
                        let errorMessage = err;
                        if (err.error === 'No internet') {
                            errorMessage = err.error;
                            this.toastService.present('ANIMALS.NO_INTERNET_NO_LIST', '', true, 4000);
                        } else if (err.statusText === 'Unknown Error') {
                            errorMessage = 'The server is offline, try it again later.';
                        } else if (err) {
                            errorMessage = 'The username or password is incorrect';
                        }
                        return of(animalsActions.setAnimalsError({
                            errorMessage,
                            blnNumber: action.blnNumber,
                            ettCode: action.ettCode
                        }));
                    })
                );
            })
        )
    );

    getMultipleAnimals$ = createEffect(
        () => this.actions.pipe(
            ofType(animalsActions.getMultipleAnimals, animalsActions.getMultipleAnimalsStart),
            flatMap((action: any) => {
                return from(this.testsApiService.getAnimals(action.blnNumber, action.ettCode)).pipe(
                    map((animals: any) => {
                        if (animals) {
                            this.animalService.setAnimalsList(animals, action.blnNumber, action.ettCode);
                            this.animalService.setAnimalsDate(action.blnNumber, action.ettCode);
                        }
                        return animalsActions.setMultipleAnimals({ animals });
                    }),
                    catchError((err) => {
                        let errorMessage = err;
                        if (err.error === 'No internet') {
                            errorMessage = err.error;
                            this.toastService.present('ANIMALS.NO_INTERNET_NO_LIST', '', true, 4000);
                        } else if (err.statusText === 'Unknown Error') {
                            errorMessage = 'The server is offline, try it again later.';
                        } else if (err) {
                            errorMessage = 'The username or password is incorrect';
                        }
                        return of(animalsActions.setMultipleAnimalsError({
                            errorMessage,
                            blnNumber: action.blnNumber,
                            ettCode: action.ettCode
                        }));
                    })
                );
            })
        )
    );

    constructor(private actions: Actions,
                private router: Router,
                private testsApiService: TestsApiService,
                private testService: TestsService,
                private animalService: AnimalsService,
                private authService: AuthService,
                private toastService: ToastService) {

    }
}
