import { createReducer, on } from '@ngrx/store';
import { animalsActions } from '@app/store/animals/animals.actions';
import { authActions } from '@app/store/auth';
import { initialAnimalsState } from '@app/store/animals/animals.state';

export const AnimalsReducer = createReducer(
    initialAnimalsState,

    on(animalsActions.getAnimals, (state, { blnNumber, ettCode }) => {
        return ({ ...state, blnNumber, ettCode,  isLoading: true, hasError: false, errorMessage: null, animals: null });
    }),

    on(animalsActions.setAnimals, (state, { animals }) => {
        return ({ ...state, animals, isLoading: false });
    }),

    on(animalsActions.setLocalSavedAnimals, (state, { animals, blnNumber, ettCode }) => {
        return ({ ...state, blnNumber, ettCode, animals, isLoading: false });
    }),

    on(animalsActions.setAnimalsError, (state, { errorMessage }) => {
        return ({ ...state, isLoading: false, errorMessage, hasError: true, animals: [] });
    }),

    on(animalsActions.getMultipleAnimalsStart, (state, { total }) => {
        return ({ ...state, multipleAnimals: { total, count: 0, isLoading: true, success: false, hasError: false, errorMessage: null  } });
    }),

    on(animalsActions.setMultipleAnimalsError, (state, { errorMessage }) => {
        return ({ ...state, multipleAnimals: { ...state.multipleAnimals, isLoading: false, hasError: true, errorMessage } });
    }),

    on(animalsActions.getMultipleAnimals, (state, { total }) => {
        return ({ ...state, multipleAnimals: { ...state.multipleAnimals, total, isLoading: true, success: false } });
    }),

    on(animalsActions.setMultipleAnimals, (state) => {
        const count = state.multipleAnimals.count + 1;
        let success = false;
        let isLoading = true;
        if (state.multipleAnimals.total === count) {
            success = true;
            isLoading = false;
        }
        return ({ ...state, multipleAnimals: { ...state.multipleAnimals, isLoading, success, count } });
    }),

    on(authActions.logOut, (state, action) => {
        return initialAnimalsState;
    }),
);

export function reducer(state, action) {
    return AnimalsReducer(state, action);
}
